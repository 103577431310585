import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-study-case-detail',
  templateUrl: './study-case-detail.component.html',
  styles: []
})
export class StudyCaseDetailComponent implements OnInit {
  @Input() case;
  constructor() { }

  ngOnInit() {
  }

}
