import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-card',
  templateUrl: './portfolio-card.component.html',
  styles: []
})
export class PortfolioCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
