import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-studay-case-v-wins',
  templateUrl: './studay-case-v-wins.component.html',
  styles: []
})
export class StudayCaseVWinsComponent implements OnInit {
  item = {
    style: 'services-bg',
    title: 'V-win International Trading',
    description:
      'Lorem ipsum dolor sit amet consecteturadipisicing elit. Eius, deleniti.'
  };

  case = {
    title1: 'V-wins International Trading',
    imageSrc1: '../../assets/vwins-studycase-1.png',
    description1:
      'V-wins international trading is a Calgary based Japanese & Korean E-commerce company. Back to the beginning of 2018 they had a very basic web based platform for customers but not easy to use. As young generations prefers shopping using mobile applications, the v-wins team decided to build a mobile platform.',
    title2: 'What we have done',
    imageSrc2: '../../assets/vwins-studycase-2.png',
    description2:
      'We designed and built the mobile application from scratch. The mobile application contains Location selection, package tracking and payment platform. are able to view all products, order, checking order status, package information and view order history. The administrators are able to modify products and check all orders status.',
    title3: 'The result',
    description3:
      'The app was downloaded more than one thousand times in the first month released and still has about 100 download monthly. The purchase orders from mobile application has 30% more than web platform.',
    imageSrc3: '../../assets/vwins-studycase-3.png'
  };
  constructor() {}

  ngOnInit() {}
}
