import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  isOpen = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onToggle() {
    this.isOpen = !this.isOpen;
    console.log('click toggle...');
  }

  onLogoClick() {
    this.router.navigate(['/home']);
  }

}
