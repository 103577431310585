import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-case-datatrail',
  templateUrl: './study-case-datatrail.component.html',
  styles: []
})
export class StudyCaseDatatrailComponent implements OnInit {
  item = {
    style: 'services-bg',
    title: 'Fleetbridate',
    description:
      'Lorem ipsum dolor sit amet consecteturadipisicing elit. Eius, deleniti.'
  };

  case = {
    title1: 'Fleetbridge Fleets Management Platform',
    imageSrc1: '../../assets/showcase-dt-1.png',
    description1:
      'DataTrail is a Calgary leading fleets tracking company which founded in 2003. DataTrail started to refactor and restrucure the whole Web platform',
    title2: 'What we have done',
    imageSrc2: '../../assets/showcase-dt-2.png',
    description2:
      'We were hired working on 3 modules: Dashboard, Notifications and Schedules. Showing daily, weekly and monthly fleets stats. Add, Remove',
    title3: 'The result',
    description3:
      'The app was downloaded more than one thousand times in the first month released and still has about 100 download monthly. The purchase orders from mobile application has 30% more than web platform.',
    imageSrc3: '../../assets/showcase-dt-3.png'
  };
  constructor() { }

  ngOnInit() {
  }

}
