import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-case-yana',
  templateUrl: './study-case-yana.component.html',
  styles: []
})
export class StudyCaseYanaComponent implements OnInit {
  item = {
    style: 'services-bg',
    title: 'Yāna Motion Lab™',
    description:
      'Lorem ipsum dolor sit amet consecteturadipisicing elit. Eius, deleniti.'
  };

  case = {
    ttile1: 'Case Study: Yāna Motion Lab™',
    description1:
      'Yāna is the first markerless motion assessment company in Canada. Yana has taken great care to ensure that our assessments are completely reproducible and objective, session to session and lab to lab.',
    imageSrc1: '../../assets/studycase-yana-3.png',
    title2: 'What we have done',
    description2:
      'We were hired for automating the motion assessment report generation process, apply complicated algorithms and logic flow to constructe Yana Normative Index Scoring System(YNI). Built an Angular & Spring Boot ERP System for the operations team which can manage clients appointments, clients information and reports information. ',
    imageSrc2: '../../assets/studycase-yana-2.png',
    title3: 'The result',
    description3:
      ' The reports generation, appointment booking, reports accessing, biomechanical comments can be done automatically. All programs are used CI/CD process, it needs one click to deploy programs to development servers or production servers.',
    imageSrc3: '../../assets/studycase-yana-1.png'
  };
  constructor() {}

  ngOnInit() {}
}
