import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styles: []
})
export class ServicesComponent implements OnInit {
  firstRowServices = [
    {
      imageSrc: '../assets/mobile.jpg',
      title: 'Mobile Development'
    },
    {
      imageSrc: '../assets/web.jpg',
      title: 'Web Development'
    },

    {
      imageSrc: '../../assets/automation.jpg',
      title: 'Work Automation'
    }
  ];

  secondRowServices = [
    {
      imageSrc: '../assets/prototyping.jpg',
      title: 'Application Prototyping'
    },
    {
      imageSrc: '../assets/analysis.jpg',
      title: 'Text Processing and Analysis'
    },

    {
      imageSrc: '../../assets/legacy.jpg',
      title: 'Legacy System Solutions'
    }
  ];
  constructor() {}

  ngOnInit() {}
}
