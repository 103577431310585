import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from '../seo.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styles: []
})
export class PortfolioComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _seoService: SEOService) { }


  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this._seoService.updateTitle(event['title']);
        this._seoService.updateOgUrl(event['ogUrl']);
        //Updating Description tag dynamically with title
        this._seoService.updateDescription(event['title'] + event['description'])
      });
  }

  onYanaClick() {
    this.router.navigate(['yana'], {relativeTo : this.activatedRoute});
  }

  onVwinsClick() {
    this.router.navigate(['v-wins'], {relativeTo : this.activatedRoute});
  }

  onFleetbridgeClick() {
    this.router.navigate(['fleetbridge'], {relativeTo : this.activatedRoute});
  }

  onRehumanClick() {
    this.router.navigate(['rehuman'], {relativeTo : this.activatedRoute});
  }

  onSniffyClick() {
    
  }
}
