import { SEOService } from './seo.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { ServiceCardComponent } from './service-card/service-card.component';
import { ServicesComponent } from './services/services.component';
import { FooterComponent } from './footer/footer.component';
import { ProcessesComponent } from './processes/processes.component';
import { ProcessCardComponent } from './process-card/process-card.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PortfolioCardComponent } from './portfolio-card/portfolio-card.component';
import { SmallBannerComponent } from './small-banner/small-banner.component';
import { StudayCaseVWinsComponent } from './studay-case-v-wins/studay-case-v-wins.component';
import { StudyCaseYanaComponent } from './study-case-yana/study-case-yana.component';
import { StudyCaseRehumanComponent } from './study-case-rehuman/study-case-rehuman.component';
import { StudyCaseDetailComponent } from './study-case-detail/study-case-detail.component';
import { StudyCaseDatatrailComponent } from './study-case-datatrail/study-case-datatrail.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    ServiceCardComponent,
    ServicesComponent,
    FooterComponent,
    ProcessesComponent,
    ProcessCardComponent,
    PortfolioComponent,
    PortfolioCardComponent,
    SmallBannerComponent,
    StudayCaseVWinsComponent,
    StudyCaseYanaComponent,
    StudyCaseRehumanComponent,
    StudyCaseDetailComponent,
    StudyCaseDatatrailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }
