import { StudyCaseDatatrailComponent } from './study-case-datatrail/study-case-datatrail.component';
import { StudyCaseYanaComponent } from './study-case-yana/study-case-yana.component';
import { StudyCaseRehumanComponent } from './study-case-rehuman/study-case-rehuman.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProcessesComponent } from './processes/processes.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { StudayCaseVWinsComponent } from './studay-case-v-wins/studay-case-v-wins.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent,
    data: {
      title: 'Hikik Software Solutions',
      description: 'Calgary&#039;s leading software development team!',
      ogUrl: 'https://hikik.ca'
    }
  },
  {
    path: 'processes', component: ProcessesComponent,
    data: {
      title: 'Process',
      description: 'Design & Development Process',
      ogUrl: 'https://hikik.ca/process'
    }
  },
  {
    path: 'portfolio', component: PortfolioComponent,
    data: {
      title: 'Portfolio',
      description: 'Showcases',
      ogUrl: 'https://hikik.ca/portfolio'
    }
  },
  { path: 'portfolio/v-wins', component: StudayCaseVWinsComponent },
  { path: 'portfolio/yana', component: StudyCaseYanaComponent },
  { path: 'portfolio/rehuman', component: StudyCaseRehumanComponent },  
  { path: 'portfolio/fleetbridge', component: StudyCaseDatatrailComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private route: ActivatedRoute) { }
}
