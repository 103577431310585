import { SEOService } from './../seo.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styles: []
})
export class ProcessesComponent implements OnInit {
  items = [
    {
      imageSrc: '../../assets/meeting.jpg',
      title: 'We meet and talk',
      content:
        'We get started by getting the brief ideas of what you are going to achieve, what we can help you. If we have chemistry we  \
        will work on the initial scope document, build a solid project plan and lock all functionalities. '
    },
    {
      imageSrc: '../../assets/prototyping.jpg',
      title: 'We design and prototype',
      content:
        'With the finalized plan and scope, our prototype team takes over to create each page of the application and this comes to the stage that we \
        both agree on the final functionalities and page.'
    },
    {
      imageSrc: '../../assets/implement.jpg',
      title: 'We work on it',
      content: 'Once the design is done, we split the whole application into multiple milestones and start coding for it. Each milestone takes a week or two \
      and at the end you will have a gorgeous and functional product.'
    },
    {
      imageSrc: '../../assets//deployment.jpg',
      title: 'Delivery',
      content: 'Wee keep each milestone lean and deliver high quality and functional product. You are able to regularly review the pgrogress  of development process \
      and provide feedback at the end of each milestone.'
    },
    {
      imageSrc: '../../assets/feedback.jpg',
      title: 'Deployment and Feedback',
      content: 'When the application is done, approved by our internal testing and project management and customer. We ship it and deploy it into production. This stage \
      we will get feedback and continue to improve until everyone is satisfied.'
    }
  ];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _seoService: SEOService) { }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this._seoService.updateTitle(event['title']);
        this._seoService.updateOgUrl(event['ogUrl']);
        //Updating Description tag dynamically with title
        this._seoService.updateDescription(event['title'] + event['description'])
      });
  }
}
