import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-case-rehuman',
  templateUrl: './study-case-rehuman.component.html',
  styles: []
})
export class StudyCaseRehumanComponent implements OnInit {
  item = {
    style: 'services-bg',
    title: 'Rehuman',
    description:
      ''
  };

  case = {
    ttile1: 'Case Study: Yāna Motion Lab™',
    description1:
      'REHUMAN is a patent-pending decentralized identity platform that reduces identity fraud, data breaches, and user bias',
    imageSrc1: '../../assets/study-case-rehuman-1.png',
    title2: 'What we have done',
    description2: 'We were hired to continue working on an existing mobile applications(one application for employers and one for potential job candidates) \
    that implemented by Flutter 2.0 and redesign the data structure in Firebase. Features like authentication, job posting(employer), job seeking(employee), barcode scan and chat system.',
    imageSrc2: '../../assets/study-case-rehuman-2.png',
    title3: 'The result',
    description3:'Both mobile apps were PoC and we finished all requested features on time and helped the company submitted their application to Y Combinator with the PoC products',
        imageSrc3: '../../assets/study-case-rehuman-3.png'
  };
  constructor() {}

  ngOnInit() {}
}
